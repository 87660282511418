import classnames from "classnames";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import SEO from "../components/Seo";
import { PageContext } from "../models";

export interface Props {
  data: {
    mdx: {
      body: string;
      frontmatter: {
        className?: string;
        title: string;
        showTitle: boolean;
      };
    };
  };
  pageContext: PageContext;
}

export const PageTemplate = ({
  data,
  pageContext: { showProfilePicture },
}: Props) => {
  const { mdx } = data;
  const { body, frontmatter } = mdx;

  return (
    <>
      <SEO title={frontmatter.title} />

      <div
        className={classnames(frontmatter.className, "prose", {
          "lg:min-w-prose": !showProfilePicture,
        })}
      >
        {frontmatter.showTitle && <h1>{frontmatter.title}</h1>}
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        path
        title
        className
        showTitle
      }
    }
  }
`;

export default PageTemplate;
